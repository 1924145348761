<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
      <h2 class="brand-text text-primary ml-1">
        {{ $t('Global.Name') }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('Error.NotFound.Title') }}
        </h2>
        <p class="mb-2">
          {{ $t('Error.NotFound.Message') }}
        </p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="redirect()">
          {{ $t('Error.NotFound.BackToHome') }}
        </b-button>
        <b-img
          fluid
          :src="require('@/assets/images/pages/not-authorized.svg')"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    Logo,
  },
  methods: {
    redirect() {
      this.$router.push({ name: isUserLoggedIn() ? 'home' : 'login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
